html {
  opacity: 0;
}
.App {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.main-content-container {
  width: calc(100vw - 300px);
  left: 300px;
}
.static-content-container {
  position: absolute;
  top: 40px;
  left: 40px;
  width: calc(100vw - 40px);
  height: calc(100vh - 80px);
  overflow: hidden auto;
  padding: 0 40px;
  padding-right: 80px;
  padding-bottom: 80px;
}

.static-header {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.static-spacer {
  margin-bottom: 2rem;
}

.static-image {
  max-width: 100%;
}

.generic-output-sequence,
.spacer-output-sequence {
  padding: 0 5px;
  border-radius: 5px;
  word-break: break-word;
}
.generic-output-sequence {
  background-color: #cccccc;
}
.spacer-output-sequence {
  background-color: #cbd5e8;
}

.ui.secondary.pointing.menu {
  cursor: pointer;
}

.menu-logo {
  height: 50px;
  padding-left: 40px;
}

@media (max-width: 800px) {
  .side-menu-container {
    display: none;
  }
  .main-content-container {
    width: 100vw;
    left: 0;
  }
}
